import '../public/cva_form.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import '../node_modules/moment'
import moment from 'moment/moment';

var app = Elm.Main.init({
  node: document.getElementById('root')
  , flags: { endpoint: process.env.ELM_APP_CNVAS_PROD_URL, year: getYear(), timestamp: getTimeStamp() }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/// ports
app.ports.sendMessage.subscribe(function (num) {
  let day = document.getElementsByName(num + '_day');
  day[0].selectedIndex = 0;
});

app.ports.sendMessageV.subscribe(function (data) {
  let json = JSON.parse(data)
  let num = json['key']
  let day = []
  app.ports.messageReceiver.send(JSON.stringify(json))

  if (json['section'] == 'wens') {
    day = document.getElementsByName(num + '_tot');
    day[0].selectedIndex = 0;
  }
})

function getYear() {
  const date = new Date()
  return date.getFullYear()
}

function getTimeStamp() {
  return moment().format()
}